@media print {
  header, footer, #sidebar {
    display: none; } }

body {
  background-image: url(/assets/bg.png); }

nav {
  padding: 0 20px; }

main .content {
  margin: 20px; }

@media only screen and (max-width: 600px) {
  html.no-header-on-small body {
    padding-bottom: 0 !important; }
  html.no-header-on-small main .content {
    margin: 0;
    height: calc(100vh); }
  html.no-header-on-small nav {
    display: none; } }

.drag-target {
  width: 20px; }

.collection a.collection-item,
.tabs .tab a {
  color: black; }

span.tooltipped, a.tooltipped {
  text-decoration: underline;
  -webkit-text-decoration-style: dotted;
  text-decoration-style: dotted; }

.collapsible-body p, .collapsible-body ul {
  padding: 20px; }

.sos-preloader-container {
  position: relative; }

.sos-lock-container {
  position: relative; }
  .sos-lock-container .sos-lock-overlay {
    display: block; }

.sos-preloader-overlay, .sos-lock-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.sos-preloader-overlay {
  background-color: rgba(0, 0, 0, 0.75); }
  .sos-preloader-overlay .sos-preloader-overlay-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

.sos-lock-overlay {
  background-color: rgba(0, 0, 0, 0.25); }
  .sos-lock-overlay .sos-lock-overlay-inner {
    margin: 20px;
    padding: 0 10px;
    font-size: small;
    text-align: center;
    color: white;
    background-color: rgba(0, 0, 0, 0.75);
    border: 1px solid black; }

@media only screen and (min-width: 601px) {
  header, main, footer {
    padding-left: 238px; } }

#sidebar {
  width: 238px; }
  #sidebar .heading {
    background-image: url(/assets/sidebar-bg.png);
    padding: 15.8666666667px 7.9333333333px;
    line-height: 0; }
  #sidebar .legal {
    color: black;
    font-size: 80%;
    text-align: center; }

#page-thanks ul.backers {
  text-align: justify; }
  #page-thanks ul.backers li {
    display: inline-block; }
    #page-thanks ul.backers li#me {
      background-color: gold; }
    #page-thanks ul.backers li:after {
      content: ', '; }
    #page-thanks ul.backers li:last-child:after {
      content: ''; }

#page-thanks ul.staff li i {
  color: darkgrey; }

#page-schedule ul.collapsible .collapsible-header {
  font-size: 150%;
  text-align: center; }

#page-schedule ul.collapsible .collapsible-body img {
  width: 100%; }

#index-login .collapsible-header, #index-reset .collapsible-header {
  font-size: 150%; }

#index-login .collapsible-body, #index-reset .collapsible-body {
  padding: 20px; }

.collapsible-body .message {
  font-size: 85%; }
  .collapsible-body .message p {
    padding-bottom: 0; }

html.js #page-settings input.hidden {
  display: none; }

#page-faq .collapsible-body {
  background-color: white; }

#page-navigation .directions {
  text-align: center;
  margin-top: 30px; }
  #page-navigation .directions a {
    min-width: 75%; }

#welcome-photos {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px; }
  #welcome-photos img {
    position: absolute;
    display: none;
    top: 0;
    left: 0; }
    #welcome-photos img:first-child {
      position: static;
      display: inline; }

#index-video iframe, #index-video .lazyload-thumbnail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

#index-video .lazyload-youtube {
  position: relative;
  padding-top: 56.25%;
  cursor: pointer; }
  #index-video .lazyload-youtube .lazyload-thumbnail img {
    width: 100%;
    height: 100%; }
  #index-video .lazyload-youtube .play {
    position: absolute;
    width: 64px;
    height: 64px;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-image: url(/assets/yt-play-hover.png);
    -moz-opacity: 0.70;
    opacity: 0.70;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha" Opacity=70; }
  #index-video .lazyload-youtube:hover .play {
    -moz-opacity: 1;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha" Opacity=100; }

#index-video .iframe-container {
  position: relative;
  padding-top: 56.25%; }

.photo {
  border: 9px solid white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

.show-on-load {
  display: none; }

.ticket-small {
  width: 100%;
  height: 100%;
  background-color: lightblue;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .ticket-small .barcode-img {
    padding: 20px 20px 0 20px;
    background-color: white;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1; }
  .ticket-small .barcode-txt {
    text-align: center;
    font-weight: bold;
    font-size: 150%;
    padding: 5px 30px;
    background-color: white;
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-flow: row nowrap;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; }

#ticket-small-portrait {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }
  @media all and (orientation: landscape) {
    #ticket-small-portrait {
      display: none; } }
  #ticket-small-portrait .header {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 10px;
    text-align: center; }
    #ticket-small-portrait .header img {
      width: 50%; }
    #ticket-small-portrait .header > div {
      -webkit-flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto; }
  #ticket-small-portrait .data {
    margin: 10px;
    text-align: center;
    font-size: 125%; }
  #ticket-small-portrait .barcode-img {
    border-top: 1px solid black; }
  #ticket-small-portrait .barcode-txt {
    border-bottom: 1px solid black; }

#ticket-small-landscape {
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row; }
  @media all and (orientation: portrait) {
    #ticket-small-landscape {
      display: none; } }
  #ticket-small-landscape .info {
    -webkit-flex-basis: 35%;
    -ms-flex-preferred-size: 35%;
    flex-basis: 35%;
    margin: 10px;
    text-align: center; }
    #ticket-small-landscape .info img {
      width: 100%; }
  #ticket-small-landscape .barcode {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -webkit-flex-basis: 65%;
    -ms-flex-preferred-size: 65%;
    flex-basis: 65%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
    #ticket-small-landscape .barcode img {
      width: 100%; }
    #ticket-small-landscape .barcode .barcode-img, #ticket-small-landscape .barcode .barcode-txt {
      border-left: 1px solid black; }

/*# sourceMappingURL=app.css.map */
