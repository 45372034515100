@media print {
  header, footer, #sidebar {
    display: none;
  }
}


body {
  background-image: url(/assets/bg.png);
}

nav {
  padding: 0 20px;
}

main .content {
  margin: 20px;
}

@media only screen and (max-width: 600px) {
  html.no-header-on-small {
    body {
      padding-bottom: 0 !important;
    }
    main .content {
      margin: 0;
      height: calc(100vh);
    }
    nav {
      display: none;
    }
  }
}



//footer.page-footer {
//  padding-top: 0;
//}
//body {
//  display: flex;
//  min-height: 100vh;
//  flex-direction: column;
//}
//main {
//  flex: 1 0 auto;
//}

.drag-target {
  width: 20px;
}

.collection a.collection-item,
.tabs .tab a {
  color: black;
}

span.tooltipped, a.tooltipped {
  text-decoration: underline;
  text-decoration-style: dotted;
}

.collapsible-body {
  p, ul {
    padding: 20px;
  }
}


// Overlays
.sos-preloader-container {
  position: relative;
}
.sos-lock-container {
  position: relative;
  .sos-lock-overlay {
    display: block;
  }
}
.sos-preloader-overlay, .sos-lock-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.sos-preloader-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  .sos-preloader-overlay-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.sos-lock-overlay {
  background-color: rgba(0, 0, 0, 0.25);
  .sos-lock-overlay-inner {
    margin: 20px;
    padding: 0 10px;
    font-size: small;
    text-align: center;
    color: white;
    background-color:  rgba(0, 0, 0, 0.75);
    border: 1px solid black;
  }
}


// Sidebar
$sidebarwidth: 238px;
@media only screen and (min-width : 601px) {
  header, main, footer {
    padding-left: $sidebarwidth;
  }
}
#sidebar {
  width: $sidebarwidth;
  .heading {
    background-image: url(/assets/sidebar-bg.png);
    padding: ($sidebarwidth / 15) ($sidebarwidth / 30);
    line-height: 0;
  }
  .legal {
    color: black;
    font-size: 80%;
    text-align: center;
  }
}

// Ticket



// Special Thanks
#page-thanks {
  ul.backers {
    text-align: justify;
    li {
      display: inline-block;
      &#me {
        background-color: gold;
      }
      &:after {
        content: ', ';
      }
      &:last-child:after {
        content: '';
      }
    }
  }
  ul.staff {
    li {
      i {
        color: darkgrey;
      }
    }
  }
}

// Schedule
#page-schedule {
  ul.collapsible {
    .collapsible-header {
      font-size: 150%;
      text-align: center;
    }
    .collapsible-body {
      img {
        width: 100%;
      }
    }
  }
}

#index-login, #index-reset {
  .collapsible-header {
    font-size: 150%;
  }
  .collapsible-body {
    padding: 20px;
  }
}

.collapsible-body .message {
  font-size: 85%;
  p {
    padding-bottom: 0;
  }
}

// Settings forms
html.js {
  #page-settings {
    input.hidden {
      display: none;
    }
  }
}

#page-faq {
  .collapsible-body {
    background-color: white;
  }
}

#page-navigation {
  .directions {
    text-align: center;
    margin-top: 30px;
    a {
      min-width:75%;
    }
  }
}

#welcome-photos {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  img {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    &:first-child {
      position: static;
      display: inline;
    }
  }
}

#index-video {
  iframe, .lazyload-thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .lazyload-youtube {
    position: relative;
    padding-top: 56.25%;
    cursor: pointer;
    .lazyload-thumbnail img {
      width: 100%;
      height: 100%;
    }
    .play {
      position: absolute;
      width: 64px;
      height: 64px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);;
      background-image: url(/assets/yt-play-hover.png);
      -moz-opacity: 0.70;
      opacity: 0.70;
      -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=70);
    }
    &:hover .play {
      -moz-opacity: 1;
      opacity: 1;
      -ms-filter:"progid:DXImageTransform.Microsoft.Alpha"(Opacity=100);
    }
  }
  .iframe-container {
    position: relative;
    padding-top: 56.25%;
  }
}

.photo {
  border: 9px solid white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.show-on-load {
  display: none;
}

// Ticket (scannable)
.ticket-small {
  width: 100%;
  height: 100%;
  background-color: lightblue;
  display: flex;
  .barcode-img {
    padding: 20px 20px 0 20px;
    background-color: white;
    flex-grow: 1;
  }
  .barcode-txt {
    text-align: center;
    font-weight: bold;
    font-size: 150%;
    padding: 5px 30px;
    background-color: white;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
#ticket-small-portrait {
  @media all and (orientation: landscape) {
    display: none;
  }
  flex-direction: column;
  .header {
    display: flex;
    align-items: center;
    margin: 10px;
    text-align: center;
    img {
      width: 50%;
    }
    > div {
      flex: 1 1 auto;
    }
  }
  .data {
    margin: 10px;
    text-align: center;
    font-size: 125%
  }
  .barcode-img {
    border-top: 1px solid black;
  }
  .barcode-txt {
    border-bottom: 1px solid black;
  }
}
#ticket-small-landscape {
  @media all and (orientation: portrait) {
    display: none;
  }
  flex-direction: row;
  .info {
    flex-basis: 35%;
    margin: 10px;
    text-align: center;
    img {
      width: 100%;
    }
  }
  .barcode {
    flex-grow: 1;
    flex-basis: 65%;
    display: flex;
    flex-direction: column;
    img {
      width: 100%;
    }
    .barcode-img, .barcode-txt {
      border-left: 1px solid black;
    }
  }
}